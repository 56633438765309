var menu = function () {

    this.bindEvents = function () {
        $('#nestable').nestable();
        $('#nestable').nestable('collapseAll');

        $('.dd-handle a').on('mousedown', function(e) {
            e.stopPropagation();
            app.href();
        });

        $('.dd-handle [data-interaction=delete]').on('mousedown', function(e) {
            e.stopPropagation();
        });

        $('#nestable').on('change', function() {
            var url = $("#nestable").attr("data-url");
            var menu = $('#nestable').nestable('serialize');

            app.block(1);
            $.post(url, {menu: menu, operation: 'reorder'})
                .success(function (data) {
                    if(data.response) {
                        app.success("", "Ordinamento riuscito!");
                    } else {
                        app.warning("", data.message);
                        app.reload();
                    }
                    app.block(0);
                })
                .error(function () {
                    console.error("Errore Ajax!");
                    app.error("", "Errore Ajax!");
                    app.block(0);
                    app.reload();
                });

        });

        $('[data-interaction=delete]').unbind('click').bind('click', function () {
            var id = $(this).attr('data-id');

            if (confirm('Sei sicuro di voler eliminare questa voce e i relativi figli?')) {
                app.block(1);
                $.delete(app.baseUrl + '/menu/' + id)
                    .done(function (data) {
                        if (data.response) {
                            app.reload();
                        } else {
                            app.warning("", data.message);
                        }
                        app.block(0);
                    })
                    .fail(function () {
                        app.block(0);
                        app.error("", "Errore AJAX!");
                    });
            }
        });
    };

    this.bindEvents();

};