var confirmation = function () {

    var confirmation = this;

    this.bindEvents = function () {
        $("#foto").dropzone({
            url: app.baseUrl + "/child/" + $("#id_child").val() + "/img",
            headers: $.ajaxSetup().headers,
            addRemoveLinks: true,
            dictRemoveFile: 'Rimuovi la foto',
            dictRemoveFileConfirmation: 'Sicuro di voler rimuovere la foto?',
            maxFiles: 1,
            maxFilesize: $("#foto").attr('data-max'),
            acceptedFiles: 'image/*',
            init: function () {
                var size = $("#foto").attr('data-size');
                var name = $("#foto").attr('data-name');

                var self = this;
                if ($.trim(size) != "") {
                    // oggetto file per dropzone
                    var file = {
                        name: name,
                        size: size,
                        status: 'success',
                        accepted: true
                    };

                    // fingo di aggiungere un nuovo documento a dropzone
                    self.emit("addedfile", file);
                    self.createThumbnailFromUrl(file, app.baseUrl + "/child/" + $("#id_child").val() + "/img");
                    self.emit("complete", file);

                    // aggiungo il mio file all'array
                    self.files.push(file);
                }
            },
            sending: function (file, data) {
                $("#spinner").show();
            },
            success: function (file, data) {
                $("#spinner").hide();
                if (!data.response) {
                    app.warning("", data.message);
                    this.removeFile(file);
                }
            },
            error: function (file, data) {
                app.warning("", data);
                $("#spinner").hide();
                this.removeFile(file);
            },
            removedfile: function (file) {
                if (file.status == "success") {
                    $("#spinner").show();
                    $.delete(app.baseUrl + "/child/" + $("#id_child").val() + "/img")
                        .done(function (data) {
                            $("#spinner").hide();

                            if (data.response) {
                                $(document).find(file.previewElement).remove();
                            } else {
                                app.warning("", data.message);
                            }
                        })
                        .fail(function () {
                            $("#spinner").hide();
                            app.error("", "Errore server. Riprovare più tardi o contattare il supporto.");
                        });
                } else {
                    $(document).find(file.previewElement).remove();
                }
            },
            maxfilesexceeded: function (file) {
                app.warning("", "Puoi caricare una sola foto");
                this.removeFile(file);
            }
        });

        $('[data-interaction=print]').unbind('click').bind('click', function () {
            var id = $(this).attr('data-id');

            app.block(1);
            $.post(app.baseUrl + "/confirmation/" + id + "/print", {})
                .done(function (data) {
                    if (data.response) {
                        window.location.href = app.baseUrl + "/confirmation/" + id + "/print";
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", "Errore Server!");
                });
        });
    };

};