var formatter = {

    bool: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data ? '<i class="fa fa-check green"></i>' : '<i class="fa fa-times red"></i>';
    },

    euro: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data + " €";
    },

    coloredBackgroup: function  (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data == "Approvato") {
            return "<b style='color: green'>" + cell_data + "</b>";
        }
        if (cell_data == "In Attesa") {
            return "<b style='color: orange'>" + cell_data + "</b>";
        }
        if (cell_data == "Rifiutato") {
            return "<b style='color: darkred'>" + cell_data + "</b>";
        }

    },

    actions: function (table, td, cell_data, row_data, row_index, col_index) {
        if ($.trim(cell_data) == "" || parseInt(cell_data) == 0)
            return "";

        var split_val = cell_data.split("_");
        console.log(cell_data);
        console.log(split_val);
        console.log(split_val[0]);
        if (split_val[0] == 1)
            return '<a href="' + app.baseUrl + "/#confirmation/" + split_val[1] + '/edit" class="btn btn-xs btn-info"><i class="fa fa-check-square"></i> Conferma</a>';
        else
            return '<a href="' + app.baseUrl + "/confirmation/create?id_subscription=" + split_val[1] + '" class="btn btn-xs btn-success"><i class="fa fa-check-square"></i> Conferma</a>';
    }

};