var subscription = function(options) {

    this.bindEvents = function() {
        $("[data-interaction=modificaStatoSubscription]").unbind('click').bind('click', function () {

            var $form = $("#" + options.form);
            var stringaStato = "";
            var stato = parseInt($(this).attr("data-stato"));

            if (stato == parseInt(options.approvato)) {
                stringaStato = "Approvare";
            } else{
                if (stato == parseInt(options.rifiutato)) {
                    stringaStato = "Rfiutare";
                } else {
                    app.warning("Errore","Impossibile identificare l'operazione da eseguire");
                }
            }


            if(confirm(stringaStato + " la richiesta di iscrizione?")) {
                var url = $(this).attr("data-url");
                var id = $form.attr("data-id");

                app.block(1);
                $.post(url, {id:id, stato: stato})
                    .success(function (data) {
                        if(data.response) {
                            app.success("Aggiornamento stato effettuato con successo");
                            app.reload();
                        } else {
                            app.warning("", data.message);
                        }
                        app.block(0);
                    })
                    .error(function () {
                        app.block(0);
                        app.error("", "Ajax error!");
                    });
            }
        });

        $('[data-interaction=print]').unbind('click').bind('click', function () {
            var id = $(this).attr('data-id');

            app.block(1);
            $.post(app.baseUrl + "/subscription/" + id + "/print", {})
                .done(function (data) {
                    if (data.response) {
                        window.location.href = app.baseUrl + "/subscription/" + id + "/print";
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", "Errore Server!");
                });
        });
    };

    this.bindEvents();
};
